import React, {useState, useEffect} from 'react';
import FlexibleFields from '@components/organisms/FlexibleFields';
import Footer from '@components/organisms/footer';
import Layout from '@components/organisms/Layout';
import Navigation from '@components/organisms/navigation';
import SiteEnvVars from '@containers/EnvironmentVariables';
import SEO from '@containers/SEO';
import fontSetting from '@helpers/fontSettings';
import {getCookie, reloadOTBanner} from '@helpers/oneTrustGTM';
import pageUrlCleaner from '@helpers/pageUrlCleaner';
import breakpoints from '@netlify/site-settings/breakpoints';
import ReactBreakpoints from 'react-breakpoints';
import GoogleFontLoader from 'react-google-font-loader';
import langPage from '../../languageCodes.json';


const PageTemplate = props => {
    const {
        pageContext: { page, breadcrumb }
    } = props

    const { seoMetadata } = page;

    let pageLanguages = null;
    let pagePath = null;

    const cleanUrl = pageUrlCleaner({path: page.path});
    if (typeof langPage[cleanUrl] === 'undefined') {
        throw new Error('The URL does not have any languages associated.');
    }

    pageLanguages = langPage[cleanUrl];
    pagePath = cleanUrl;

    const [loaded, setLoaded] = useState(false);
    useEffect(() => setLoaded(true), []);

    return (
        <Layout page={page}>
            <SiteEnvVars env={process.env.GATSBY_GTM_SITE_ENVIRONMENT} />
            {loaded && <GoogleFontLoader fonts={fontSetting(page.language)} />}
            {loaded && (
                getCookie(),
                reloadOTBanner()
            )}
            <SEO pageLangs={pageLanguages} pagePath={pagePath} pageMeta={seoMetadata} pageTitle={page.title} breadcrumb={breadcrumb} lang={page.language} />
            <Navigation lang={page.language} pageLangs={pageLanguages} />
            <ReactBreakpoints breakpoints={breakpoints}>
                <FlexibleFields lang={page.language} pagePath={page.path} fields={page.flexibleFields} />
            </ReactBreakpoints>
            <Footer lang={page.language} pageLangs={pageLanguages} />
        </Layout>
    )
}

export default PageTemplate
